import React, { useEffect, useState } from 'react';
import { Button, Dropdown, DropdownFilter, Input, InputPercent, InputNumber, Modal } from '@cognitiv/cyprus-ui';
import { clearLineItem } from 'ducks/actions/line-items';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { createFlightLineItem, updateLineItems } from 'ducks/operators/line-items';
import { transformLineItem } from 'ducks/transforms/line-items';
import { lineItemValidation } from 'ducks/validations/line-items';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';
import { formatNumber } from 'utils/numbers';
import { mergeQuery } from 'utils/queries';
import { v4 as uuidv4 } from 'uuid';

import cn from './Modal.module.scss';

const default_state = {
  line_item_id: null,
  line_item_name: '',
  platform_name: '',
  platform_id: null,
  line_item_type_name: '',
  line_item_type_id: null,
  filter_name: '',
  filter_id: null,
  kpi_group_name: '',
  kpi_group_id: null,
  flight_name: '',
  flight_id: null,
  overserve_factor: null,
  overserve_factor_formatted: '',
  discrepancy_tolerance_min: 0.7,
  discrepancy_tolerance_min_formatted: '0.7',
  discrepancy_tolerance_max: 1.5,
  discrepancy_tolerance_max_formatted: '1.5',
  ab_test_segment_name: '',
  ab_test_segment_id: null,
  ab_test_segment_override: true,
  filter_override_true: true,
  configuration_json: {},
  state_id: 1,
};

export const ManageLineItem = ({ location, history, match }) => {
  const dispatch = useDispatch();

  const { modals, segments, platforms_list, filters, line_item_types_list, campaign, line_item } = useSelector(
    (state) => ({
      flight: state.flight,
      segments: state.segments,
      platforms_list: state.platforms_list,
      filters: state.filters,
      modals: state.modals,
      line_item_types_list: state.line_item_types_list,
      line_item: state.line_item,
      campaign: state.campaign,
    }),
  );

  const [form, setForm] = useState({ ...default_state });

  useEffect(() => {
    const { default_overserve_factor, campaign_kpi_group_id } = campaign;
    const overserve_factor_formatted = formatNumber(default_overserve_factor, {
      format: 'percentage-multiplied',
      decimals: 1,
    });

    setForm({
      ...default_state,
      ...line_item,
      kpi_group_id: campaign_kpi_group_id,
      overserve_factor: default_overserve_factor,
      overserve_factor_formatted,
    });
  }, [campaign, line_item]);

  const onChange = (item) => {
    setForm((prev) => ({
      ...prev,
      ...item,
    }));
  };

  const closeModal = () => {
    // checking params to validate on line item page
    if (!match.params.line_item_id) {
      dispatch(clearLineItem());
      setForm({ ...default_state });
    }

    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ manage_line_item: false }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { pathname, search } = location;
    const { flight_id, campaign_id } = match.params;
    const { line_item_id } = line_item;
    const queries = mergeQuery(search, { update: uuidv4() });

    try {
      // line item update empty frequency caps
      const post_data = transformLineItem(form);

      if (line_item_id) {
        await dispatch(updateLineItems(campaign_id, [post_data]));
      }
      if (!line_item_id) {
        await dispatch(createFlightLineItem(flight_id, post_data));
      }
      history.push(`${pathname}${queries}`);
      dispatch(updateSuccess('line item has been successfully updated'));
    } catch (err) {
      handleError(err);
    }
    closeModal();
  };

  const {
    line_item_name,
    platform_name,
    line_item_type_name,
    overserve_factor_formatted,
    discrepancy_tolerance_min_formatted,
    discrepancy_tolerance_max_formatted,
    ab_test_segment_name,
    filter_name,
  } = form;

  const disabled = lineItemValidation(form);
  return (
    <Modal
      padding={16}
      close_box={46}
      name="manage_line_item"
      show={modals.manage_line_item}
      width={500}
      onClose={closeModal}
      standard={false}
    >
      <h3>{form.line_item_id ? 'Update Line Item' : 'Create Line Item'}</h3>
      <form onSubmit={onSubmit}>
        <Dropdown
          label="Platform"
          options={platforms_list}
          value={platform_name}
          option_key="name"
          margin="0px 0px 15px 0px"
          disabled={form.line_item_id}
          onSelect={(item) => onChange({ platform_id: item.id, platform_name: item.name })}
        />
        <Input
          label="Line Item Name"
          value={line_item_name}
          onChange={(value) => onChange({ line_item_name: value })}
          margin="0px 0px 15px 0px"
        />
        <Dropdown
          label="Line Item Type"
          options={line_item_types_list}
          value={line_item_type_name}
          option_key="name"
          onSelect={(item) => onChange({ line_item_type_id: item.id, line_item_type_name: item.name })}
          margin="0px 0px 15px 0px"
        />
        <InputPercent
          label="Overserve Factor"
          decimal_scale={1}
          value={overserve_factor_formatted}
          onChange={(input) =>
            onChange({ overserve_factor_formatted: input.formatted_value, overserve_factor: input.float_value })
          }
          margin="0px 0px 15px 0px"
        />
        <div style={{ display: 'flex' }}>
          <InputNumber
            label="Discrepancy Tolerance Min"
            decimal_scale={2}
            value={discrepancy_tolerance_min_formatted}
            onChange={(input) =>
              onChange({ discrepancy_tolerance_min_formatted: input.formatted_value, discrepancy_tolerance_min: input.float_value })
            }
            margin="0px 7px 15px 0px"
          />
          <InputNumber
            label="Discrepancy Tolerance Max"
            decimal_scale={2}
            value={discrepancy_tolerance_max_formatted}
            onChange={(input) =>
              onChange({ discrepancy_tolerance_max_formatted: input.formatted_value, discrepancy_tolerance_max: input.float_value })
            }
            margin="0px 0px 15px 7px"
          />
        </div>
        <DropdownFilter
          label="AB Test Segment"
          options={segments.filter((segment) => segment.identity_graph_id === campaign.identity_graph_id)}
          value={ab_test_segment_name}
          option_key="segment_name"
          onSelect={(item) =>
            onChange({ ab_test_segment_id: item.segment_id, ab_test_segment_name: item.segment_name })
          }
          onChange={(value) => {
            onChange({
              ab_test_segment_id: null,
              ab_test_segment_name: value,
              uuid: null,
            });
          }}
          margin="0px 0px 15px 0px"
        />
        <Dropdown
          label="Filter"
          options={filters}
          value={filter_name}
          option_key="filter_name"
          onSelect={(item) => onChange({ filter_id: item.filter_id, filter_name: item.filter_name })}
        />
        <div className={cn.buttonContainer}>
          <div className={cn.flex} />
          <Button button_size="small" width="100px" margin="10px 0px 0px 0px" type="submit" disabled={!disabled}>
            {form.line_item_id ? 'Save' : 'Create'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

ManageLineItem.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};
