export const transformLineItems = (data = []) => data.map((d) => transformLineItem(d));

export const transformLineItem = (data = {}) => ({
  line_item_id: data.line_item_id,
  line_item_name: data.line_item_name,
  platform_id: data.platform_id,
  overserve_factor: data.overserve_factor,
  discrepancy_tolerance_min: data.discrepancy_tolerance_min,
  discrepancy_tolerance_max: data.discrepancy_tolerance_max,
  state_id: data.state_id,
  kpi_group_id: data.kpi_group_id,
  ab_test_segment_id: data.ab_test_segment_id,
  frequency_caps: [], // frequency caps are controlled outside line item
  filter_id: data.filter_id,
  flight_id: data.flight_id,
  line_item_type_id: data.line_item_type_id,
  ab_test_segment_override: data.ab_test_segment_override,
  filter_override: data.filter_override,
  configuration_json: data.configuration_json,
  is_dcpm: data.is_dcpm,
});
