import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input, Modal } from '@cognitiv/cyprus-ui';
import { clearFilter } from 'ducks/actions/filters';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { createAdvertiserFilter, updateFilter } from 'ducks/operators/filters';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';
import { mergeQuery } from 'utils/queries';
import { v4 as uuidv4 } from 'uuid';

import cn from './Modal.module.scss';

const default_state = {
  filter_name: '',
  append_global_criteria: false,
  filter_id: null,
};

export const ManageFilter = ({ history, location, match }) => {
  const dispatch = useDispatch();

  const { filter, modals } = useSelector(
    (state) => ({
      filter: state.filter,
      modals: state.modals,
    }),
    shallowEqual,
  );

  const [form, setForm] = useState({ ...default_state });

  useEffect(() => {
    setForm({ ...default_state, ...filter });
  }, [filter]);

  const onChange = (item) => {
    setForm((prev) => ({
      ...prev,
      ...item,
    }));
  };

  const closeModal = () => {
    // checking params to validate on advertiser page
    if (!match.params.filter_id) {
      dispatch(clearFilter());
      setForm({ ...default_state });
    }

    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ manage_filter: false }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { search, pathname } = location;
    const { advertiser_id, filter_id } = match.params;
    const queries = mergeQuery(search, { update: uuidv4() });

    try {
      const data = {
        filter_name: form.filter_name,
        append_global_criteria: form?.append_global_criteria,
        filter_json: filter?.filter_json || [],
      };

      // forcing page update to fire get routes
      if (filter_id) {
        await dispatch(updateFilter(filter_id, data));
        history.push(`${pathname}${queries}`);
      }
      if (!filter_id) {
        const entity_id = await dispatch(createAdvertiserFilter(advertiser_id, data));
        history.push(`/advertisers/${advertiser_id}/filters/${entity_id}/features?feature_definition_id=33`);
      }
      dispatch(updateSuccess('filter has been successfully updated'));
    } catch (err) {
      handleError(err);
    }
    closeModal();
  };

  const { filter_id } = filter;
  const { filter_name, append_global_criteria } = form;

  return (
    <Modal
      padding={16}
      close_box={46}
      name="manage_filter"
      show={modals.manage_filter}
      width={450}
      onClose={closeModal}
      standard={false}
    >
      <h3>{filter_id ? 'Update Filter' : 'Create Filter'}</h3>
      <form onSubmit={onSubmit}>
        <Input
          auto_focus
          label="Filter Name"
          value={filter_name}
          onChange={(value) => onChange({ filter_name: value })}
        />
        <Checkbox
          margin="10px 7px 0px 0px"
          width="max-content"
          checked={append_global_criteria}
          onClick={(checked) => onChange({ append_global_criteria: checked })}
        >
          Append Global Criteria
        </Checkbox>
        <div className={cn.buttonContainer}>
          <div className={cn.flex} />
          <Button button_size="small" width="100px" margin="10px 0px 0px 0px" type="submit" disabled={!filter_name}>
            {filter_id ? 'Save' : 'Create'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

ManageFilter.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};
